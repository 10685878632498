@import "keep-react/css";
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@300;400;600&display=swap");
/* @import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities"; */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

/* @font-face {
  font-family: 'Gagalin';
  src: url('../src/assets/fonts/Gagalin-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
} */

/* Estilos para la barra de desplazamiento */
/* Cambia el tamaño y el color de la barra de desplazamiento */
::-webkit-scrollbar {
  width: 12px; /* ancho de la barra vertical */
  height: 12px; /* altura de la barra horizontal */
}

/* Estilo para el fondo del track (la pista de la barra) */
::-webkit-scrollbar-track {
  background: #f0f0f0; /* color de fondo del track */
  border-radius: 10px; /* bordes redondeados para el track */
}

/* Estilo para el thumb (la parte móvil de la barra) */
::-webkit-scrollbar-thumb {
  background-color: #888; /* color del thumb */
  border-radius: 10px; /* bordes redondeados para el thumb */
  border: 3px solid #f0f0f0; /* borde entre el thumb y el track */
}

/* Cambia el estilo del thumb al pasar el mouse */
::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* color del thumb cuando el mouse está encima */
}
