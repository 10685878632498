@keyframes loading {
  0% {
    background-color: rgba(220, 220, 220, 0.5); /* Gris claro con opacidad */
  }
  50% {
    background-color: rgba(
      170,
      170,
      170,
      0.7
    ); /* Gris medio con más opacidad */
  }
  100% {
    background-color: rgba(
      220,
      220,
      220,
      0.5
    ); /* Vuelve al gris claro inicial */
  }
}

.skeleton {
  animation: loading 1.5s infinite;
  background-color: rgba(85, 85, 85, 0.5);
  border-radius: 4px;
  display: inline-block;
}
.skeleton_img {
  display: inline-block;
  width: 24px; /* Asumiendo que quieres un círculo de 48px de diámetro. Ajústalo según tus necesidades. */
  height: 24px;
  background-color: rgba(85, 85, 85, 0.5);
  border-radius: 50%; /* Esto transformará el elemento en un círculo. */
  animation: loading 1.5s infinite;
}
