.accordion-title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .accordion-content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .panel{
    --tw-bg-opacity:1;
    --tw-border-opacity:1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    border-color: rgb(233 239 246 / var(--tw-border-opacity));
  }
  @media (max-width: 768px) {
    .accordion-title {
      flex-direction: column;
      align-items: flex-start;
    }
    .accordion{
        width: 94vw;
    }
    .accordion td {
      display:inline-block;
      padding:0.5rem;
      padding-right: 10px;
      padding-left: 10px;
        /* padding:3px; */
        /* width:100%; */
    }
    .accordion th {
        display:inline-block;
        /* padding:3px; */
        width:100%;
    }
    .thead{
        display: none;
    }
  }
  