.date-picker-container {
    padding: 0;
}

.date-picker-label {
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    color: #4A4A4A;
    margin-bottom: 0.5rem;
    font-weight: bold;
}

.date-picker-input {
    padding: 0.5rem;
    border: 1px solid #E2E8F0;
    border-radius: 0.25rem;
    color: #4A4A4A;
    font-size: 0.875rem;

}

@keyframes loading {
  0% {
    background-color: rgba(220, 220, 220, 0.5); /* Gris claro con opacidad */
  }
  50% {
    background-color: rgba(
      170,
      170,
      170,
      0.7
    ); /* Gris medio con más opacidad */
  }
  100% {
    background-color: rgba(
      220,
      220,
      220,
      0.5
    ); /* Vuelve al gris claro inicial */
  }
}

.skeleton {
  animation: loading 1.5s infinite;
  background-color: rgba(85, 85, 85, 0.5);
  border-radius: 4px;
  display: inline-block;
}
