.pagination-controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 8px 16px;
  }
  
  .pagination-button {
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0 8px;
  }
  
  .pagination-button:disabled {
    color: #ccc;
    cursor: default;
  }
  
  .pagination-button:hover:not(:disabled) {
    color: #000;
  }
  @media (max-width: 768px) {
    .table{
        width: 94vw;
        border-collapse: separate;
        border-spacing: 0 10px;
    }
    .table td {
        display:inline-block;
        padding:0.5rem;
        padding-right: 10px;
        padding-left: 10px;
        /* width:100%; */
        border: none;
    }
    .table th {
        display:inline-block;
        /* padding:3px; */
        width:100%;
        border: none;
    }
    .table tr {
      /* background: #f2f2f2; */
      border: 1px solid #ddd;
    }
    .table tr {
      border-radius: 10px;
      overflow: hidden;
      /* border: 1px solid #c1c1c1; */
      display: block;
      margin-bottom: 10px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    }
    .thead{
        display: none;
    }
    .filter{
      margin-bottom:0;
    }
    .modal{
      width: 90vw;
    }
  }