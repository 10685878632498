.login-container {
  width: 100vw;
  height: 100vh;
  background-color: #f0f0f0; /* Color de fondo como respaldo */
  background-size: 80%;
  background-position: right;
  background-repeat: no-repeat;
  background-color: #fff;
}
.form-container {
  position: absolute; /* O 'fixed' si quieres que siempre esté visible en la pantalla */
  top: 40%;
  right: 0;
  transform: translateY(-50%); /* Centra el contenedor verticalmente */
  width: 300px; /* O el ancho que prefieras */
  height: auto; /* Ajusta según el contenido, o define una altura específica */
  padding: 30px; /* Espacio interno alrededor del contenido del formulario */
  background-color: rgba(
    255,
    255,
    255,
    0.5
  ); /* Fondo blanco con transparencia */
  backdrop-filter: blur(10px); /* Efecto de desenfoque en el fondo */
  border-radius: 10px; /* Bordes redondeados para el contenedor */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra suave para dar profundidad */
  z-index: 1; /* Asegura que el formulario esté por encima del fondo */
}

.input-container {
  position: relative;
  width: fit-content; /* Ajusta al contenido, asegurando que el TextInput no se estire */
}

.TextInput {
  width: 100%; /* Asegura que el TextInput ocupe todo el ancho del contenedor */
}

.show-password-button {
  position: absolute;
  right: 10px; /* Posición desde la derecha del contenedor */
  top: 50%; /* Centra el botón verticalmente */
  transform: translateY(
    -50%
  ); /* Ajuste fino para centrar exactamente el botón */
  border: none;
  background: none;
  cursor: pointer;
}

.login-header {
  font-family: "Sora", sans-serif;
  /* font-family: "Roboto", sans-serif; */
  position: absolute;
  left: 30%; /* Posiciona el elemento a la mitad de la mitad izquierda de la pantalla */
  top: 30%; /* Inicia el elemento en el centro vertical de la pantalla */
  transform: translate(-50%, -50%); /* Ajusta la posición exacta del elemento */
  font-size: 3.1rem; /* Tamaño del texto */
  margin-left: 10px;
}
.login-subheader {
  /* font-family: "Gagalin", sans-serif; */
  font-family: "Roboto", sans-serif;
  position: absolute;
  left: 30%; /* Posiciona el elemento a la mitad de la mitad izquierda de la pantalla */
  top: 40%; /* Inicia el elemento en el centro vertical de la pantalla */
  transform: translate(-50%, -50%); /* Ajusta la posición exacta del elemento */
  font-size: 2rem; /* Tamaño del texto */
  margin-left: 15px;
}

.descripcion {
  position: absolute;
  font-family: "Roboto", sans-serif;
  left: 30%; /* Posiciona el elemento a la mitad de la mitad izquierda de la pantalla */
  top: 40%; /* Inicia el elemento en el centro vertical de la pantalla */
  transform: translate(-50%, -50%); /* Ajusta la posición exacta del elemento */
  font-size: 1.4rem; /* Tamaño del texto */
  margin-left: 15px;
  font-weight: 600;
}

/* @keyframes fadeInOut {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
} */

.title-container {
  font-family: "Sora", sans-serif;
  font-weight: 600;
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader-container {
  position: fixed; /* Posicionamiento fijo en relación con el viewport */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(
    255,
    255,
    255,
    0.5
  ); /* Fondo blanco con transparencia */
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  z-index: 9999; /* Asegura que esté por encima de otros elementos */
}

.loader {
  border: 8px solid rgba(240, 240, 240, 0.3); /* Fondo con un poco de transparencia */
  border-radius: 50%; /* Forma circular */
  border-top: 8px solid blue; /* Borde superior azul */
  width: 60px; /* Ancho */
  height: 60px; /* Altura */
  animation: spin 1s linear infinite; /* Animación */
}

/* en tu archivo .css o .scss */
@keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @media (max-width: 768px) {
    .title-container{
      display: none;
    }
  }