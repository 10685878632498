.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.loader {
    border: 8px solid rgba(240, 240, 240, 0.3); /* Fondo con un poco de transparencia */
    border-radius: 50%; /* Forma circular */
    border-top: 8px solid blue; /* Borde superior azul */
    width: 60px; /* Ancho */
    height: 60px; /* Altura */
    animation: spin 1s linear infinite; /* Animación */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.logo-comercio {
    width: 45px;
    height: 45px;
    object-fit: cover;  /* Esto mantendrá las proporciones de la imagen, cubriendo todo el espacio definido */
}
